import WIP from "../components/WIP/WIP";

const WhyAnimals = () => {
  return (
    <div>
      <WIP />
    </div>
  );
};

export default WhyAnimals;
