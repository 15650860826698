import { IRecipe } from "../../models/recipe.models";

export default {
  id: "meatlovers-pizza",
  categories: ["dinner", "lunch"],
  name: `"Meatlover's" Pizza`,
  rating: 4,
  time: 4,
  cost: 3,
  image: "/images/pizza/pizza_slices.jpg",
  servings: 2,
  shortDescription: `A meatlovers pizza with Vegan Pepperoni, Bacon, and topped with BBQ sauce. An easy and delicious homemade pizza that is perfect for those who follow a plant-based diet but still like a little "meat" to their meals.`,
  nutrition: {
    protein: 34.2,
    fatTotal: 45,
    carbohydrates: 120.3,
    calcium: 77,
    calories: 1042,
    iron: 14,
    sodium: 2298,
  },
  components: [
    {
      componentName: "Pizza Dough",
      note: "You can probably buy some pre-made vegan pizza bases, but these homemade bases are easy to make and delicious!",
      ingredients: [
        {
          name: "Warm Water",
          notes: "Not too warm, or it will kill the yeast!",
          measurement: "cups",
          quantity: 3 / 4,
        },
        {
          name: "Instant Yeast",
          measurement: "tsp",
          quantity: 2.25,
        },
        {
          name: "Granulated Sugar",
          measurement: "tsp",
          quantity: 1,
        },
        {
          name: "Salt",
          measurement: "tsp",
          quantity: 1,
        },
        {
          name: "Olive Oil",
          measurement: "tbsp",
          quantity: 2,
        },
        {
          name: "All Purpose Flour",
          measurement: "cups",
          quantity: 2,
        },
      ],
      steps: [
        {
          text: "Stir together the {Warm Water}, {Instant Yeast}, and {Granulated Sugar}. Let it sit for 10 minutes - it should become foamy.",
          image: "/images/pizza/pizza_dough_1.jpg",
        },
        {
          text: "Stir in the {Olive Oil} and {Salt}",
        },
        {
          text: "Sift and Mix in the {All Purpose Flour} 1/2 a cup at a time.",
        },
        {
          text: "Knead the dough until it forms a cohesive ball. It will still be a little sticky but you should be able to pick it up as one piece. If it's far too sticky, add 1-2 more tablespoons of flour at a time until it comes together.",
        },
        {
          text: "Continue to knead for 5 minutes.",
        },
        {
          text: "Place the ball of dough into a large greased bowl. Cover with a towel and let it rise in a warm location for 1 hour or until doubled in size.",
          image: "/images/pizza/pizza_dough_2.jpg",
        },
        {
          text: "Punch the dough down and knead a couple of times until it becomes smooth and more firm",
        },
      ],
    },
    {
      componentName: "Pizza Toppings",
      ingredients: [
        {
          name: "White Button Mushroom",
          quantity: 2,
          measurement: "unit",
          isOptional: true,
        },
        {
          name: "Capsicum",
          quantity: 1 / 2,
          measurement: "unit",
          isOptional: true,
        },
        {
          name: "Vegan Bacon",
          notes: "I recommend the Sunfed brand Boar-Free Bacon.",
          quantity: 50,
          measurement: "grams",
          isOptional: true,
          link: "https://www.newworld.co.nz/shop/product/5266999_ea_000nw",
        },
        {
          name: "Vegan Pepperoni",
          notes: "I recommend the Grater-Goods Pepperoni.",
          quantity: 100,
          measurement: "grams",
          isOptional: true,
          link: "https://www.paknsave.co.nz/shop/product/5302333_ea_000pns?name=plant-based-pepperoni",
        },
      ],
      steps: [
        {
          text: "If you are using Bacon or Pepperoni, cut these fake-meats into small pieces and then lightly pan-fry them. Remember that they will be in the oven at the end so they don't need to be fully cooked through.",
        },
        {
          text: "If you are using vegetables, cute them into small slices. You will not need to pre-cook the vegetables.",
        },
      ],
      note: "You can use any combination of toppings you desire, but I will recommend some for you!",
    },
    {
      componentName: "Making the Pizza",
      ingredients: [
        {
          name: "Olive Oil",
          measurement: "tbsp",
          quantity: 2,
          notes: "To brush on the crust before pre-bake",
        },
        {
          name: "Vegan butter",
          measurement: "tbsp",
          quantity: 2,
          notes: "To brush on the crust after pre-bake",
          link: "https://www.thecrueltyfreeshop.co.nz/vegan-food/butter-spreads/vutter",
        },
        {
          name: "Pizza Sauce",
          measurement: "cups",
          quantity: 1 / 3,
          notes: "For the base of the pizza",
        },
        {
          name: "Vegan Mozzarella",
          measurement: "grams",
          quantity: 100,
          notes:
            "Pre-Grated is preferable. I recommend the Angel Food grated cheese.",
          link: "https://www.countdown.co.nz/shop/productdetails?stockcode=413916&name=angel-food-dairy-free-cheese-alternative-mozzarella",
        },
      ],
      steps: [
        {
          text: "Pre-heat oven to 200 degrees Celcius (fan bake)",
        },
        {
          text: "Roll out the dough on a floured surface. Use your pizza pan for size reference (this recipe makes one 12-inch thick-crust pizza per 2 servings)",
        },
        {
          text: "Sprinkle the pizza pan with a pinch or two of flour (or cooking oil) and transfer the dough onto the pan. Work the dough to fit the edges.",
        },
        {
          text: "Brush the dough with {Olive Oil} (this helps the top of the dough stay moist and not overcook.)",
        },
        {
          text: "Poke the dough all over with a fork - this will prevent the dough from puffing up.",
          image: "/images/pizza/pizza_base_1.jpg",
        },
        {
          text: "Pre-bake the dough for 5-7 minutes or until the bottom is just barely beginning to brown. At this point, the pizza should be able to lift out of the pan with a spatula.",
        },
        {
          text: "Remove from the oven, brush the crust with {Vegan Butter}, spread the {Pizza Sauce} acrosss the pizza base and then cover generously with {Vegan mozzarella}.",
          image: "/images/pizza/pizza_base_2.jpg",
        },
        {
          text: "Cover the pizza generously with {Vegan mozzarella}.",
          image: "/images/pizza/pizza_base_3.jpg",
        },
        {
          text: "Add your other toppings of choice before putting the pizza back in the oven. Bake for another 10-15 minutes or until the crust is golden brown and the topping are cooked through.",
        },
      ],
    },
  ],
} as IRecipe;
