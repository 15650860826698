import { IngredientMeasurement } from "../../models/recipe.models";

export const speedText = (speed: number | null) => {
  switch (speed) {
    case 1:
      return "Up to: Instant";
    case 2:
      return "Up to: Fast";
    case 3:
      return "Up to: Medium";
    case 4:
      return "Up to: Slow";
    case 5:
      return "Up to: Long";
    default:
      return "Time to prepare";
  }
};

export const costText = (cost: number | null) => {
  switch (cost) {
    case 1:
      return "Up to: Very Cheap";
    case 2:
      return "Up to: Cheap";
    case 3:
      return "Up to: Moderate";
    case 4:
      return "Up to: Pricey";
    case 5:
      return "Up to: Luxury";
    default:
      return "Cost";
  }
};

export const quantityValueText = (value: number | undefined) => {
  if (value === undefined) {
    return "";
  }

  const wholeNumber = Math.floor(value);

  const remainderValue = value - wholeNumber;

  if (remainderValue === 0) {
    return wholeNumber;
  }

  let remainderText = `${remainderValue}`;

  switch (remainderValue) {
    case 1 / 16:
      remainderText = "1/16";
      break;
    case 1 / 8:
      remainderText = "1/8";
      break;
    case 1 / 5:
      remainderText = "1/5";
      break;
    case 1 / 4:
      remainderText = "1/4";
      break;
    case 1 / 3:
      remainderText = "1/3";
      break;
    case 1 / 2:
      remainderText = "1/2";
      break;
    case 2 / 3:
      remainderText = "2/3";
      break;
    case 2 / 5:
      remainderText = "2/5";
      break;
    case 3 / 4:
      remainderText = "3/4";
      break;
    default:
      break;
  }

  if (wholeNumber === 0) {
    return remainderText;
  }

  return `${wholeNumber} ${remainderText}`;
};

export const measurementName = (
  quantity: number,
  measurement: IngredientMeasurement | undefined
) => {
  switch (measurement) {
    case undefined:
      return "";
    case "cups":
      return quantity <= 1 ? "cup" : "cups";
    case "unit":
      return quantity <= 1 ? "unit" : "units";
    default:
      return measurement;
  }
};

export const quantityText = (
  quantity: number,
  measurement: IngredientMeasurement | undefined
) => {
  return `${quantityValueText(quantity)} ${measurementName(
    quantity,
    measurement
  )}`;
};

export const pluraliseIngredient = (
  ingredient: string,
  quantity: number,
  measurement: IngredientMeasurement | undefined
) => {
  let isPlural = false;

  switch (measurement) {
    case "unit":
      isPlural = quantity > 1;
      break;
    default:
      break;
  }

  return `${ingredient}${isPlural ? "s" : ""}`;
};
