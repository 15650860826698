import cn from "classnames";

interface IRecipeNutritionItemProps {
  name: string;
  value: number;
  measurement: "g" | "mg" | "";
  isBold?: boolean;
}

const RecipeNutritionItem = (props: IRecipeNutritionItemProps) => {
  return (
    <div className="panel-block">
      <div className={cn("flex w-100 justify-between", { fw7: props.isBold })}>
        <div>{props.name}</div>
        <div>
          {props.value}
          {props.measurement}
        </div>
      </div>
    </div>
  );
};

export default RecipeNutritionItem;
