import { IRecipe } from "../../models/recipe.models";
import RecipeNutritionItem from "./RecipeNutritionItem";

interface IRecipeNutritionPanelProps {
  recipe: IRecipe;
}

const RecipeNutritionPanel = (props: IRecipeNutritionPanelProps) => {
  const { nutrition } = props.recipe;

  if (nutrition === undefined) {
    return null;
  }

  return (
    <div className="panel is-danger">
      <div className="panel-heading">Nutritional Information</div>
      <div className="panel-content">
        <div className="panel-block i">
          This is a rough estimate of the nutrition from 1 serving.
        </div>
        <RecipeNutritionItem
          name="Calories"
          value={nutrition.calories}
          measurement=""
          isBold
        />
        <RecipeNutritionItem
          name="Total Fat"
          value={nutrition.fatTotal}
          measurement="g"
          isBold
        />
        <RecipeNutritionItem
          name="Protein"
          value={nutrition?.protein}
          measurement="g"
          isBold
        />
        <RecipeNutritionItem
          name="Carbohydrates"
          value={nutrition.carbohydrates}
          measurement="g"
          isBold
        />
        <RecipeNutritionItem
          name="Sodium"
          value={nutrition.sodium}
          measurement="mg"
        />
        <RecipeNutritionItem
          name="Calcium"
          value={nutrition.calcium}
          measurement="mg"
        />
        <RecipeNutritionItem
          name="Iron"
          value={nutrition.iron}
          measurement="mg"
        />
      </div>
    </div>
  );
};

export default RecipeNutritionPanel;
